import { graphql } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import React from "react";
import ButtonWithIcon from "../components/Button/ButtonWithIcon";
import ButtonSolid from "../components/Button/ButtonSolid";
import Layout from "../components/Layout";
import CallToAction from "../components/Repeating/CTA2";
import SearchEngineOptimization from "../components/SEO";
import ServiceBar from "../components/Navigation/ServiceBar"
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import coinstack from "../images/homepage/3-coinstack.png";

export const data = graphql`
  {
    blockImage: file(relativePath: { eq: "homepage/block-image.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, width: 530)
      }
    }
    homepageCoinStack: file(relativePath: { eq: "homepage/3-coinstack.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, width: 420)
      }
    }
    homepageVenDiagram: file(relativePath: { eq: "homepage/2-intro.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, width: 530)
      }
    }
    homepageBio: file(relativePath: { eq: "homepage/suzie-homepage.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, width: 530)
      }
    }
    grayCrosses: file(relativePath: { eq: "locations/gray-crosses.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    homepageHeroCoins: file(
      relativePath: { eq: "homepage/hero-illustration-grouped.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 530)
      }
    }
    privateWealthCoin: file(relativePath: { eq: "homepage/coin.svg" }) {
      publicURL
    }
    parallaxCoinSix: file(relativePath: { eq: "common/landing/coinSix.png" }) {
      publicURL
    }
    parallaxCoinFive: file(
      relativePath: { eq: "common/landing/coinFive.png" }
    ) {
      publicURL
    }
    parallaxCoinFour: file(
      relativePath: { eq: "common/landing/coinFour.png" }
    ) {
      publicURL
    }
    parallaxCoinThree: file(
      relativePath: { eq: "common/landing/coinThree.png" }
    ) {
      publicURL
    }
    parallaxCoinTwo: file(relativePath: { eq: "common/landing/coinTwo.png" }) {
      publicURL
    }
    parallaxCoinOne: file(relativePath: { eq: "common/landing/coinOne.png" }) {
      publicURL
    }
    sectionHeroCoins: file(relativePath: { eq: "common/landing/coinSix.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, quality: 100)
      }
    }
    homepageHeroCoins: file(
      relativePath: { eq: "homepage/hero-illustration-grouped.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 530)
      }
    }
    homepageCoinStack: file(relativePath: { eq: "homepage/3-coinstack.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, width: 420)
      }
    }
    homepageDictionary: file(
      relativePath: { eq: "homepage/dictionary.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 540)
      }
    }
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Global.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Global.jpg" }
    ) {
      publicURL
    }
  }
`;

const Page = ({ data }) => {
  const { props } = data;

  return (
    <Layout showDisclaimer serviceType="wealth" >
      <SearchEngineOptimization
        title="Expert Wealth Management Solutions | Dark Horse Private Wealth"
        description="Discover personalized wealth management strategies designed for your unique financial needs. Begin your journey to a prosperous future with our expert advisors."
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      {/* PARALLAX */}
      <section className="relative hidden overflow-hidden lg:block ">
        <div className="relative h-screen overflow-hidden bg-[#1E2A33] ">
          <section className=" relative bg-[#1E2A33] pt-8   text-white">
            <div className="container relative max-h-[770px] overflow-hidden">
              <div className="  grid items-center gap-y-8 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
                <div className="mb-20">
                  <h1>
                    <span className="text-secondary-50">A Better Way to</span>
                    <br />
                    <span className="text-secondary-50">Build Wealth.</span>
                  </h1>
                  <p>
                  Wealth Management without Tax Planning is not Wealth Management. Build wealth smarter with Dark Horse.

                  </p>

                  <div className="no-wrap flex gap-x-10">
                  <ButtonWithIcon
              href="/pricing/"
              text="Get Started"
              altStyle={2}
            />
                  </div>
                </div>
                <div className="relative">
                  <GatsbyImage
                    objectFit="contain"
                    imgClassName="absolute w-full left-0 bottom-0 z-[-5]"
                    className="left-10 bottom-0 w-full"
                    image={
                      data.homepageHeroCoins.childImageSharp.gatsbyImageData
                    }
                  />
                </div>
              </div>
            </div>
          </section>
          <ParallaxProvider>
            {/* coin one */}
            <div>
              <Parallax speed={15} translateY={["20px", "150px"]}>
                <div className="left-15 absolute bottom-[38rem] right-[9rem] z-10">
                  <img src={data.parallaxCoinOne.publicURL} className="" />
                </div>
              </Parallax>
            </div>
            {/* end of coin one */}
            {/* coin Two */}
            <div>
              <Parallax speed={10} translateY={["50px", "250px"]}>
                <div className="left-15 absolute bottom-[36rem] right-[26rem] z-10">
                  <img src={data.parallaxCoinTwo.publicURL} className="" />
                </div>
              </Parallax>
            </div>
            {/* end of coin Two */}
            {/* coin three */}
            <div>
              <Parallax speed={5} translateY={["20px", "250px"]}>
                <div className="left-15 absolute bottom-[24rem] right-[13rem] z-10">
                  <img src={data.parallaxCoinThree.publicURL} className="" />
                </div>
              </Parallax>
            </div>

            {/* end of coin three */}
            {/* coin Four */}
            <div>
              <Parallax speed={2} translateY={["50px", "250px"]}>
                <div className="left-15 absolute bottom-[20rem] right-[30rem] z-10">
                  <img src={data.parallaxCoinFour.publicURL} className="" />
                </div>
              </Parallax>
            </div>
            {/* end of coin Four */}
            {/* coin five */}
            <div>
              <Parallax
                speed={15}
                translateX={["30px", "-25px"]}
                translateY={["50px", "250px"]}
              >
                <div className="left-15 absolute bottom-[-2rem] right-[12rem] z-10">
                  <img src={data.parallaxCoinFive.publicURL} className="" />
                </div>
              </Parallax>
            </div>
            {/* end of coin five */}
          </ParallaxProvider>
        </div>
        <div className=" absolute h-screen bg-primary-900">
          <ParallaxProvider className="pl-24">
            {/* coin one */}
            <div>
              <Parallax speed={15} translateY={["20px", "150px"]}>
                <div className="left-15 absolute bottom-[38rem] right-[9rem] z-10">
                  <img src={data.parallaxCoinOne.publicURL} className="" />
                </div>
              </Parallax>
            </div>
            {/* end of coin one */}
            {/* coin Two */}
            <div>
              <Parallax speed={10} translateY={["50px", "250px"]}>
                <div className="left-15 absolute bottom-[36rem] right-[26rem] z-10">
                  <img src={data.parallaxCoinTwo.publicURL} className="" />
                </div>
              </Parallax>
            </div>
            {/* end of coin Two */}
            {/* coin three */}
            <div>
              <Parallax speed={5} translateY={["20px", "250px"]}>
                <div className="left-15 absolute bottom-[24rem] right-[13rem] z-10">
                  <img src={data.parallaxCoinThree.publicURL} className="" />
                </div>
              </Parallax>
            </div>

            {/* end of coin three */}
            {/* coin Four */}
            <div>
              <Parallax speed={2} translateY={["50px", "250px"]}>
                <div className="left-15 absolute bottom-[20rem] right-[30rem] z-10">
                  <img src={data.parallaxCoinFour.publicURL} className="" />
                </div>
              </Parallax>
            </div>
            {/* end of coin Four */}
            {/* coin five */}
            <div>
              <Parallax speed={15} translateY={["50px", "425px"]}>
                <div className=" absolute bottom-[-12rem] right-[2rem] z-10">
                  <img src={data.parallaxCoinFive.publicURL} className="" />
                </div>
              </Parallax>
            </div>
            {/* end of coin five */}
          </ParallaxProvider>
        </div>
      </section>
      {/* END OF PARALLAX */}
      {/* MOBILE STATIC BG */}
      <section className="relative block bg-[#1E2A33] pt-10 lg:hidden   ">
        <div className="container relative h-[550px] overflow-hidden">
          <div className=" grid items-center justify-center gap-y-8 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className=" h-full">
              <h1>
                <span className="text-secondary-50">A Better Way to</span>
                <br />{" "}
                <span className="text-secondary-50">Build Wealth.</span>
              </h1>
              <p className="text-secondary-50">
                Increase your returns by lowering your fees and increasing your
                tax efficiency with Dark Horse Private Wealth.
              </p>

              <div className="no-wrap flex gap-x-10">
              <ButtonWithIcon
              href="/philosophy/"
              text="Learn More"
              altStyle={2}
            />
              </div>
            </div>
            <div className="relative">
              <GatsbyImage
                objectFit="contain"
                imgClassName="absolute w-full left-0 bottom-0"
                className="left-10 bottom-0 w-full"
                image={data.homepageHeroCoins.childImageSharp.gatsbyImageData}
              />
            </div>
          </div>
        </div>
      </section>
      {/* END OF MOBILE STATIC BG */}

      <section className=" pt-10 pb-20 md:pt-16 md:pb-20 lg:pt-26 lg:pb-32">
        <div className="container">
          <div className="grid items-center gap-y-18 md:grid-cols-2 md:gap-x-16 lg:gap-x-28">
            <div className=" order-1 md:order-2">
              <h3>
                <span className="">More Than Wealth Management.</span> <br />
                Wealth<span> </span>
                <span className="text-secondary-500">
                  <i>Building</i>.
                </span>
              </h3>
              <p className="max-w-[466px]">
              Dark Horse can handle it all—from your personal finances and taxes to the forecasting, analysis, and strategy you need for your business. And because we're CPAs, we're not just managing your wealth. We're deploying strategies that help you keep more of what we grow together.
              </p>

             
            </div>
            <div className="order-2 md:order-1">
              <div className="relative ml-9 mr-4 md:ml-0 lg:mr-0">
                <div className="relative z-10">
                  <GatsbyImage
                    image={
                      data.homepageVenDiagram.childImageSharp.gatsbyImageData
                    }
                    className="z-0 w-full rounded-4xl"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-secondary-100 pt-10 pb-20 md:pt-16 md:pb-20 lg:pt-26 lg:pb-32">
        <div className="container">
          <div className="grid items-center gap-y-18 md:grid-cols-2 md:gap-x-8 lg:gap-x-16">
          <div className="order-2 md:order-2">
              <div className="relative ml-9 mr-4 md:ml-0 lg:mr-0">
                <div className="relative z-10">
                  <GatsbyImage
                    image={
                      data.homepageBio.childImageSharp.gatsbyImageData
                    }
                    className="z-0 w-full rounded-4xl"
                  />
                </div>
              </div>
            </div>
            <div className=" order-1 md:order-1">
              <h2>
                <span className="">The Dark Horse<br /> Advantage</span> 
              </h2>
              <p className="max-w-[400px]">
              Accountants and Financial Advisors who are on the same team...YOUR team.
              </p>

              
            </div>
            
          </div>
        </div>
      </section>

      <section className="bg-[#1E2A33]  text-white  ">
        {/* MOBILE STATIC BG */}
        <div className="relative overflow-hidden">
          <div className=" h-full">
            <div>
              <div className="left-15 absolute top-[-210px] right-[1rem] z-10">
                <img src={data.parallaxCoinFive.publicURL} className="" />
              </div>
            </div>
          </div>
          {/* END OF MOBILE STAIC BG */}
          <ParallaxProvider className="pl-24">
            {/* coin Six */}
            {/* <div>
              <Parallax
                speed={15}
                translateX={["30px", "-25px"]}
                translateY={["50px", "250px"]}
              >
                <div className="left-15 absolute bottom-[-2rem] right-[1rem] z-10">
                  <img src={data.parallaxCoinSix.publicURL} className="" />
                </div>
              </Parallax>
            </div> */}
            {/* end of coin Six */}
          </ParallaxProvider>
          <div className="container pt-10 md:pt-16  lg:pt-26 ">
            

            <div className="grid items-center gap-y-18 md:grid-cols-2 md:gap-x-16 lg:gap-x-28 mb-16 md:mb-0">
              <div className="order-2 md:order-1 max-w-[560px] md:pb-[150px]">
                <h3 className="text-white">
                Your After-Tax Return Matters Most
                </h3>
                <p>
                Tax saving strategies only work when clients, CPAs and CFPs can collaborate seamlessly. This rarely happens without the right structure or alignment.
                </p>
                <p>
                Dark Horse has created an integrated offering that provides its clients with advisory services that strike the right balance between tax efficiency and prudent investing.
                </p>

                <ButtonWithIcon
              href="/philosophy/"
              text="Learn More"
              altStyle={2}
            />
              </div>
              <div className="order-1 md:order-2 md:px-[80px]">
                <div className="relative ml-9 mr-4 md:ml-0 lg:mr-0">
                  <div className="relative z-10">
                  <img src={coinstack} className="max-w-[320px] bottom" />
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className=" pt-10 pb-20 md:pt-16 md:pb-20 lg:pt-26 lg:pb-32">
        <div className="container">
          <div className="grid items-center gap-y-18 md:grid-cols-2 md:gap-x-16 lg:gap-x-28">
            <div className="order-2">
              <h3 className="max-w-[400px]">
                <span className="text-primary-500">The problem</span>
                <span className="text-[#485159]">
                  {" "}
                  with traditional firms that <span className="bolder italic text-black">aren't</span> Dark Horse
                </span>
              </h3>
              <p>
                The traditional wealth management firm wants you to bring all of
                your assets under their management, and as a thank you, they’ll
                covertly fee you to death.
              </p>
              <div className="w-full">
                <ul className="grid gap-y-3 md:gap-y-[5px]">
                  <li className="flex items-center gap-x-3 py-[5px]">
                    <i className="fa fa-1xl fa-times" />
                    <span>
                      They’ll tell you they work well with CPAs, but those
                      relationships won’t add any value to your portfolio.
                    </span>
                  </li>
                  <li className="flex items-center gap-x-3 py-[5px]">
                    <i className="fa fa-1xl fa-times" />
                    <span>
                      They’ll tell you they’re better than the other firms, but
                      they’re neither smarter nor more well-connected than their
                      competitors.
                    </span>
                  </li>
                  <li className="flex items-center gap-x-3 py-[5px]">
                    <i className="fa fa-1xl fa-times" />
                    <span>
                      They’ll sell you on the value of the ongoing relationship,
                      but all they’re really doing is trying to prevent you from
                      making irrational decisions.
                    </span>
                  </li>
                </ul>
              </div>
              
            </div>
            <div className="order-1">
              <div className="relative ml-9 mr-4 md:ml-0 lg:mr-0">
                <div className="relative z-10">
                  <GatsbyImage
                    image={
                      data.homepageDictionary.childImageSharp.gatsbyImageData
                    }
                    className="z-0 w-full"
                  />
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="relative pt-10 pb-20 md:pt-16 md:pb-20 lg:pt-26 lg:pb-32">
        <div className="absolute top-0 left-0 h-full w-full bg-primary-900"></div>
        <div className="absolute top-0 left-0 h-full w-full ">
          <div className=" zigZagBg h-full"></div>
        </div>

        <div className="container relative">
          <div className="relative   rounded-xl px-4 py-6 md:px-16 md:py-16">
            <h2 className="modern-quote-text">
              Fee-heavy, exotic financial products pedaled by the big banks and
              wirehouses rarely beat the market over the long term. The best way
              to build wealth is to reduce the largest drag on your
              investments...taxes.
            </h2>
          </div>
        </div>
      </section>
      <section className="bg-white pt-10 pb-20 md:pt-16 md:pb-20 lg:pt-26 lg:pb-32">
        <div className="container">
          <div className="grid items-center gap-y-18 md:grid-cols-2 md:gap-x-16 lg:gap-x-28">
            <div className="order-2 md:order-1">
              <h2>Why Dark Horse <br />vs. The Rest?</h2>

              <div>
                <h4>Unparalleled Value & Service</h4>{" "}
               
                <p>
                  Take a look for yourself…how many CPA firms can you find
                  online with more{" "}
                  <AniLink className="text-[#E1C05F] no-underline hover:underline hover:text-[#E1C05F]" fade target="_blank" to="https://darkhorse.cpa/reviews/">
                    5-star reviews
                  </AniLink>
                  , a Net Promotor Score of 90%, and being named one of Fortune magazine’s{" "}
                  <AniLink className="text-[#E1C05F] no-underline hover:underline hover:text-[#E1C05F]"
                    fade
                    target="_blank"
                    to="https://fortune.com/best-small-workplaces/2022/dark-horse-cpas/"
                  >
                    Best Places to Work
                  </AniLink>?
                </p>
                <p>
                Our advisors bring the institutional-level investment opportunities historically reserved for thoroughbreds to the Dark Horse investor.
                </p>
              </div>

              
            </div>
            <div className="order-1 md:order-2">
              <div className="relative ml-9 mr-4 md:ml-0 lg:mr-0">
                <div className="relative z-10">
                  <GatsbyImage
                    image={data.blockImage.childImageSharp.gatsbyImageData}
                    className="z-0 w-full rounded-4xl"
                  />
                  
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" pt-10 md:pt-16 lg:pt-26"></div>
      </section>
      <section className="bg-secondary-100 mb-0">
        <div class="container py-[50px]">
      <CallToAction rotatingWords="Taxes" />
      </div>
      </section>
    </Layout>
  );
};

export default Page;
